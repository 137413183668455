import React, { useCallback } from "react"
import { useAsync } from "react-async"
import { submitToNewsletter } from "./api"
import { Button, Container, Flex, Input, Label } from "theme-ui"
import { Field, Formik, Form } from "formik"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { object, string } from "yup"

const useRequest = (onRun, config = {}) =>
  useAsync({
    ...config,
    deferFn: useCallback(args => onRun(...args), [onRun]),
  })

export const useNewsletterSubscription = onResolve => {
  const {
    isPending: isSubscribingToNewsletter,
    error: newsletterSubscriptionError,
    run: requestNewsletterSubscription,
  } = useRequest(submitToNewsletter, { onResolve })

  return {
    isSubscribingToNewsletter,
    newsletterSubscriptionError,
    requestNewsletterSubscription,
  }
}

export default () => {
  const data = useStaticQuery(graphql`
    query {
      newsletter: file(relativePath: { eq: "newsletter.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const {
    isSubscribingToNewsletter,
    newsletterSubscriptionError,
    requestNewsletterSubscription,
  } = useNewsletterSubscription()
  const formikProps = {
    initialValues: {},
    onSubmit: async values => {
      await requestNewsletterSubscription(values)
    },
    validationSchema: object().shape({
      newsletterEmail: string().email("EmailNotValid").required("Required"),
    }),
  }

  const NewsletterForm = () => (
    <Formik {...formikProps}>
      {({ dirty, handleSubmit, isSubmitting, isValid, ...props }) => (
        <Form>
          <Flex
            sx={{
              flex: "1 0 50%",
              ml: [0, null, `3rem`],
              pt: [`2rem`, null, 0],
            }}
          >
            <Field name="newsletterEmail">
              {({ field }) => (
                <Input
                  type="email"
                  placeholder="E-mail"
                  id="newsletterEmail"
                  sx={{ alignSelf: 'center', height: '48px', px: [4], py: "15px", mr: [3], flex: `1 1 100%` }}
                  {...field}
                />
              )}
            </Field>

            <Button
              disabled={!dirty || isSubscribingToNewsletter}
              type="submit"
              variant="submit"
              sx={{
                borderRadius: `30px`,
                display: "flex",
                py: `15px`,
                flex: `1 2 100%`,
                height: "auto",
              }}
            >
              ZAPISZ SIĘ!
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  )

  return (
    <Container as="section">
      <Flex variant="layout.subnav">
        <BackgroundImage fluid={data.newsletter.childImageSharp.fluid}>
          <Flex
            sx={{
              alignItems: "center",
              backgroundColor: "rgba(15, 15, 15, 0.7)",
              position: "relative",
              width: "100%",
              px: "100px",
              py: "65px",
              flexDirection: [`column`, null, `row`],
            }}
          >
            <Label
              variant="layout.newsletter"
              sx={{ flex: "1 0 50%", textAlign: [`center`, null, `left`] }}
              htmlFor="newsletterEmail"
            >
              Zapisz się do naszego newslettera i bądź na bieżąco ze wszystkimi
              eventami w okolicy
            </Label>

            <NewsletterForm />
          </Flex>
        </BackgroundImage>
      </Flex>
    </Container>
  )
}
