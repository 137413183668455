import axios from "axios"

const maileriteApiKey = process.env.MAILERITE_API_KEY

const config = {
  "X-MailerLite-ApiKey": "fc7b8c5b32067bcd47cafb5f475d2fe9",
  "Access-Control-Allow-Headers": "x-mailerLite-apikey",
}

export const submitToNewsletter = data =>
  axios.post("https://api.mailerlite.com/api/v2/subscribers", data, {
    headers: config,
  })
