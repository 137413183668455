/** @jsx jsx */
import { jsx, IconButton, Image } from "theme-ui"
import facebook from "@images/social/light/facebook.svg"
import twitter from "@images/social/light/twitter.svg"
import linkedin from "@images/social/light/linkedin.svg"
import instagram from "@images/social/light/instagram.svg"
import youtube from "@images/social/light/youtube.svg"

const socialButtons = [
  { name: "Facebook", icon: facebook, url: "https://facebook.com" },
  { name: "Twitter", icon: twitter, url: "https://twitter.com" },
  { name: "LinkedIn", icon: linkedin, url: "https://linkedin.com" },
  { name: "Instagram", icon: instagram, url: "https://instagram.com" },
  { name: "Youtube", icon: youtube, url: "https://youtube.com" },
]

export default () =>
  socialButtons.map(({ name, icon, url }) => (
    <a href={url} key={name}>
      <IconButton>
        <Image alt={name} src={icon} />
      </IconButton>
    </a>
  ))