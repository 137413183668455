import React from "react"
/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import "react-netlify-identity-widget/styles.css" // delete if you want to bring your own CSS
import { Container, Flex, NavLink, Text } from "theme-ui"
import Link from "@common/Link"
import SocialMediaButtons from "@components/SocialMediaButtons"
import Logo from "@common/Logo"

export default () => (
  <Container as="footer">
    <Flex
      bg="transparent"
      sx={{ display: "flex", width: "auto" }}
      variant="layout.footerContainer"
    >
      <Flex variant="layout.footerSocialLinks">
        <Logo alt="Dworld logo" variant="footerLogo" />
        <Text variant="layout.footer.text">
          House My Brand designs clothing for the young, the old & everyone in
          between – but most importantly, for the fashionable
        </Text>
        <Flex>
          <SocialMediaButtons />
        </Flex>
      </Flex>
      <Flex variant="layout.footer.column">
        <Styled.h5>Menu</Styled.h5>
        <ul>
          <li>
            <Link variant="footer" to="/calendar">
              Kalendarz Eventowy
            </Link>
          </li>
          <li>
            <Link variant="footer" to="/workshops">
              Workshopy
            </Link>
          </li>
          <li>
            <Link variant="footer" to="/conferences">
              Konferencje
            </Link>
          </li>
          <li>
            <Link variant="footer" to="/meetups">
              Meetupy
            </Link>
          </li>
          <li>
            <Link variant="footer" to="/convents">
              Konwenty
            </Link>
          </li>
        </ul>
      </Flex>
      <Flex variant="layout.footer.column">
        <Styled.h5>Informacje</Styled.h5>
        <ul>
          <li>
            <Link variant="footer" to="/faq">
              FAQ
            </Link>
          </li>
          <li>
            <Link variant="footer" to="/policy">
              Polityka Prywatności
            </Link>
          </li>
        </ul>
      </Flex>
      <Flex variant="layout.footer.column">
        <Styled.h5>Kontakt</Styled.h5>
        <ul>
          <li>
            <NavLink to="mailto:hello@dworld.pl" variant="footer">hello@dworld.pl</NavLink>
          </li>
          <li>
            <NavLink to="#" variant="footer">telefon: +1 131 138 138</NavLink>
          </li>
        </ul>
      </Flex>
    </Flex>
    <Container
      bg="transparent"
      variant="footer"
    >
      dworld.pl © 2020, all rights reserved
    </Container>
  </Container>
)
