/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import "normalize.css"

import { Container, Flex } from "theme-ui"
import { Global } from "@emotion/core"
import Logo from "@common/Logo"
import AvatarButton from "@components/AvatarButton"
import MainNavigation from "@components/MainNavigation"
import Newsletter from "@forms/Newsletter"
import Footer from "@components/Footer"
import globalStyles from "@styles/global"
import { colorModes } from "@const"

const Layout = ({ children, colorMode = colorModes.dark }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Global styles={globalStyles} />
      <Container as="header" variant="layout.header">
        <Logo colorMode={colorMode} />
        <AvatarButton colorMode={colorMode} />
      </Container>
      <MainNavigation colorMode={colorMode} />
      <Flex as="main" variant="layout.main">
        {children}
      </Flex>
      <Newsletter />
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
