import React from "react"
import { Box, Flex, Heading, Styled } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Link from "@common/Link"

export const bgImage = graphql`
  fragment bgImage on File {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`

export default ({ header }) => {
  const data = useStaticQuery(graphql`
    query {
      workshops: file(relativePath: { eq: "subnav/workshops.png" }) {
        ...bgImage
      }
      conferences: file(relativePath: { eq: "subnav/conferences.png" }) {
        ...bgImage
      }
      meetups: file(relativePath: { eq: "subnav/meetups.png" }) {
        ...bgImage
      }
      convents: file(relativePath: { eq: "subnav/convents.png" }) {
        ...bgImage
      }
    }
  `)

  const categories = [
    { heading: "Workshopy", id: `workshops` },
    { heading: "Konferencje", id: `conferences` },
    { heading: "Meetupy", id: `meetups` },
    { heading: "Konwenty", id: `convents` },
  ]

  return (
    <Box variant="layout.subnav">
      {header ? <Styled.h1>{header}</Styled.h1> : null}
      {categories.map(category => (
        <BackgroundImage
          key={category.id}
          fluid={data[category.id].childImageSharp.fluid}
          style={{ flex: [`1 0 100%`, null, `1 0 50%`] }}
        >
          <Flex
            variant="layout.subnav.item"
            as="section"
          >
            <Heading variant="styles.h2">{category.heading}</Heading>
            <Link variant="subnav" to={`/${category.id}`}>
              Zobacz
            </Link>
          </Flex>
        </BackgroundImage>
      ))}
    </Box>
  )
}
