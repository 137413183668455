import React, { useEffect, useState, useRef } from "react"
import Slider from "react-slick"
import { useStaticQuery, graphql } from "gatsby" // to query for image data
import Img from "gatsby-image" // to take image data and render it
import { Box, Container, Flex, Styled, useColorMode } from "theme-ui"

import { contentSliderProps, imgSliderProps } from "@const"

import Layout from "@layouts/default"

import ArrowBtn from "@components/ArrowBtn"

import Link from "@common/Link"
import SEO from "@components/seo"

import CalendarIcon from "@assets/calendarIcon.svg"
import CheckMarkIcon from "@assets/checkMarkIcon.svg"
import "slick-carousel/slick/slick.css"

import EventCategories from "@components/EventCategories"

export default () => {
  const [colorMode, setColorMode] = useColorMode()
  const [state, setState] = useState({ nav1: null, nav2: null })
  const slider1 = useRef()
  const slider2 = useRef()

  const sliderImgData = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "slides/home" }
        }
      ) {
        edges {
          node {
            base
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  useEffect(() => setColorMode("light"), [colorMode, setColorMode])

  useEffect(() => {
    setState({
      nav1: slider1.current,
      nav2: slider2.current,
    })
  }, [])

  const { nav1, nav2 } = state

  return (
    <Layout>
      <SEO title="Home" />
      <Flex as="section" variant="layout.homeContainer">
        <Box variant="layout.imgSliderContainer">
          <Slider
            asNavFor={nav2}
            ref={slider => (slider1.current = slider)}
            {...imgSliderProps}
          >
            {sliderImgData.allFile.edges.map(image => {
              return (
                <Box variant="layout.homeImgSlide" key={image.node.base}>
                  <Img
                    fluid={image.node.childImageSharp.fluid}
                    style={{ height: `960px` }}
                    alt={image.node.base.split(".")[0]} // only use section of the file extension with the filename
                  />
                </Box>
              )
            })}
          </Slider>
        </Box>

        <Container variant="layout.flexColumn">
          <Flex variant="layout.contentSliderContainer">
            <Slider
              asNavFor={nav1}
              ref={slider => (slider2.current = slider)}
              {...contentSliderProps}
            >
              <Flex variant="layout.homeContentSlide">
                <Styled.h1>
                  Bądź na bieżąco z wydarzeniami w branży IT
                </Styled.h1>
                <Link to="/calendar">
                  <Flex variant="layout.homeContentSlide.link">
                    <ArrowBtn />
                    sprawdź najbliższe eventy
                  </Flex>
                </Link>
              </Flex>
              <Flex variant="layout.homeContentSlide">
                <Styled.h1>
                  Bądź na bieżąco z wydarzeniami w branży IT 2
                </Styled.h1>
                <Link to="/calendar">
                  <Flex variant="layout.homeContentSlide.link">
                    <ArrowBtn />
                    sprawdź najbliższe eventy
                  </Flex>
                </Link>
              </Flex>
              <Flex variant="layout.homeContentSlide">
                <Styled.h1>
                  Bądź na bieżąco z wydarzeniami w branży IT 3
                </Styled.h1>
                <Link to="/calendar">
                  <Flex variant="layout.homeContentSlide.link">
                    <ArrowBtn />
                    sprawdź najbliższe eventy
                  </Flex>
                </Link>
              </Flex>
            </Slider>
          </Flex>
          <Flex
            sx={{
              flex: "0 156px",
              marginTop: "70px",
              justifyContent: "space-between",
              zIndex: 2,
            }}
          >
            <Flex
              sx={{
                bg: "white",
                borderTopRightRadius: "78px",
                paddingRight: "14rem",
              }}
            >
              <Flex sx={{ alignItems: "center", paddingLeft: "32px" }}>
                <CalendarIcon
                  style={{ height: "46px", margin: "25px", width: "46px" }}
                />
                <Flex variant="layout.flexColumn">
                  <Styled.h6>Sprawdź Nasz Kalendarz Eventów</Styled.h6>
                  <Styled.p>
                    i zapisz się na najlepsze wydarzenia branżowe
                  </Styled.p>
                </Flex>
              </Flex>
              <Flex sx={{ alignItems: "center", paddingLeft: "32px" }}>
                <CheckMarkIcon
                  style={{ height: "46px", margin: "25px", width: "46px" }}
                />
                <Flex variant="layout.flexColumn">
                  <Styled.h6>Zaloguj się i bądź na bieżąco</Styled.h6>
                  <Styled.p>
                    Sprawdź co otrzymujesz jako zarejestrowany użytkownik
                  </Styled.p>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Container>
      </Flex>

      <Container as="section">
        <EventCategories/>
      </Container>
    </Layout>
  )
}
